<template>
  <div class="top clearfix">
    <div class="left">
      <!-- 搜索 -->
      <div class="box width200 input-box">
        <search-input
          :placeholder="$t('lang.searchTText')"
          :callback="search"
          :value="filter.condition"></search-input>
      </div>
      <div
        class="box filters-toggle"
        @click="filterSectionToggle">
        <i class="icon iconfont">&#xe69c;</i> {{ $t('lang.filters') }}
      </div>
      <!-- clear filter -->
      <div
        v-show="!filterSectionAddBtn && $route.name != 'SourceMap' && !showResetFilter"
        class="clearFilters"
        @click="resetFilter">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('lang.clearFilter')"
          placement="top-start">
          <i class="icon iconfont icon-a-RestoreFilter"></i>
        </el-tooltip>
      </div>
      <span
        class="addBtn iconfont sourceAddIcon "
        @click="addSource"
        v-show="!filterSectionAddBtn && $route.name != 'SourceMap'"></span>
    </div>
    <div
      class="filters-section left"
      v-show="filterSection">
      <!-- status -->
      <div class="box set">
        <drop-menu-select
          :data="statusData"
          :typeVal="'status'"
          :class="{'filterHighBlur': hasCheckedStatus}"
          :hasOnGoing="$route.name == 'SourceMap'? false : openOnGoingEvent"
          :title="$t('lang.status')"
          :callback="changeFilter">
        </drop-menu-select>
      </div>
      <!-- bookmark -->
      <div
        class="box"
        @click="openBookmarkList"
        v-if="$route.name != 'SourceMap' && !hideBookmark">
        <book-mark
          :callback="changeFilter"
          :class="{'filterHighBlur': filter.bookmardIds != ''}"
          :from="{ Unclassified: true }"
          :defaultKeys="defaultBookmark"></book-mark>
      </div>
      <!-- type -->
      <div class="box set">
        <drop-menu-select
          :data="typeData"
          :typeVal="'type'"
          :class="{'filterHighBlur': hasCheckedType}"
          :title="$t('lang.Type')"
          :callback="changeFilter">
          <template
            slot-scope="iconVal"
            slot="deviceIcon">
            <span
              v-html="iconVal.iconVal"
              class="iconfont deviceIcon"></span>
          </template>
        </drop-menu-select>
      </div>
      <!-- 排序 -->
      <div
        class="box last-select"
        v-show="$route.name != 'SourceMap'">
        <select-menu
          :data="selectBySort"
          :callback="changeSort"
          :selectVal="selectSort"></select-menu>
      </div>
      <!-- clear filter -->
      <div
        v-show="filterSectionAddBtn && $route.name != 'SourceMap' && !showResetFilter"
        class="clearFilters"
        @click="resetFilter">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('lang.clearFilter')"
          placement="top-start">
          <i class="icon iconfont icon-a-RestoreFilter"></i>
        </el-tooltip>
      </div>
      <span
        class="addBtn iconfont sourceAddIcon"
        @click="addSource"
        v-show="filterSectionAddBtn && $route.name != 'SourceMap'"></span>
    </div>
    <div class="right rightFl">
      <!-- 分页 -->
      <div
        class="info icon"
        v-show="$route.name != 'SourceMap'">
        <Pagination
          @current-change="handleCurrentChange"
          :current-page="filter.pageNum"
          :pageSizes="[10, 15, 20, 50, 100]"
          :pageSize="filter.pageSize"
          :total="total"
          @size-change="handleSizeChange"
          layout="prev, pageF, next, pageL, nowSize, jumper">
        </Pagination>
      </div>
      <div
        class="info map"
        v-show="$route.name == 'SourceMap'">
        {{ $t('lang.refreshInterval') }}
        <div class="fresh box">
          <select-menu
            :data="freshData"
            :noTranslate="true"
            :callback="changeFresh"
            :selectVal="freshTime"></select-menu>
        </div>
        <i class="red"></i>
        <i class="green"></i>
        <i class="gray"></i>
        <span class="xy">{{ $t('lang.coordinate') }}(
          <span class="red">{{ mapNum.livingPosition }}</span>
          /<span class="green">{{ mapNum.onlinePosition }}</span>
          /<span class="gray">{{ mapNum.offlinePosition }}</span>
          <span v-show="mapNum.noPosition>0">&nbsp;{{ $t('lang.ReceiverError') }}: {{mapNum.noPosition}}</span>)
        </span>
      </div>
      <!-- 列表切换 -->
      <div class="info icon">
        <i
          class="iconfont sourceListIcon"
          :class="{ tabActive: $route.name == 'SourceContent' }"
          @click="jump('SourceContent')"></i>
      </div>
      <div class="info icon">
        <el-dropdown
          @command="changeSize"
          placement="bottom-end">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="selectSizeBox">
            <el-dropdown-item
              command="l"
              :class="[showSizeClass == 'l' ? 'green' : '']">
              <span
                class="iconfont icon-largesize"
                style="padding-right: 8px"></span>{{ $t('lang.largeSize') }}</el-dropdown-item>
            <el-dropdown-item
              command="m"
              :class="[showSizeClass == 'm' ? 'green' : '']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe63d;</span>{{ $t('lang.mediumSize') }}</el-dropdown-item>
            <el-dropdown-item
              command="s"
              :class="[showSizeClass == 's' ? 'green' : '']">
              <span
                class="iconfont"
                style="padding-right: 8px">&#xe6ef;</span>{{ $t('lang.smallSize') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="info icon">
        <i
          class="iconfont sourceInfoIcon"
          :class="{ tabActive: $route.name == 'SourceList' }"
          @click="jump('SourceList', 'jump')"></i>
      </div>
      <div class="info icon">
        <i
          class="iconfont i-map"
          :class="{ tabActive: $route.name == 'SourceMap' }"
          @click="jump('SourceMap')"></i>
      </div>
      <div class="info icon">
        <i
          class="iconfont icon-zidongdingwei"
          :class="{ tabActive: $route.name == 'SourceMapTrack' }"
          @click="chooseTrack">
        </i>
      </div>
    </div>
    <!-- 轨迹弹框 -->
    <map-track
      ref="mapTrack"
      v-show="trackAlert"></map-track>
    <!-- addSource -->
    <add-source
      v-if="add.show"
      :data="add"
      @queryList="queryInfo"></add-source>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { mapMutations, mapState } from 'vuex'
import { MySourceStatus, MySourceType, SelectBySorts } from '@/config'
import SearchInput from '@/components/Search'
import BookMark from '@/components/bookMark'
import SelectMenu from '@/components/SelectMenu'
import Pagination from '@/components/Pagination'
import AddSource from './dialog/addSource'
import DropMenuSelect from '@/components/dropMenuSelect'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
import mapTrack from './dialog/mapTrackAlert'
export default {
  name: 'sourceTop',
  components: {
    SearchInput,
    BookMark,
    SelectMenu,
    Pagination,
    AddSource,
    DropMenuSelect,
    mapTrack
  },
  props: {
    query: Function,
    mapNum: {
      type: Object,
      default: () => {
        return {
          livingPosition: 0,
          totalPosition: 0,
          noPosition: 0
        }
      }
    }
  },
  computed: {
    ...mapState({
      // 映射
      userBehaviors: state => (state.userBehaviors ? state.userBehaviors : {}),
      showSizeClass () {
        return this.$store.state.common.showDeviceSize
      },
      openOnGoingEvent () {
        return this.$store.state.common.baseUrl.openOnGoingEvent
      },
      // 有搜索信息或者筛选信息非初始化，则显示重置筛选标
      showResetFilter () {
        return this.filter.condition === '' && this.statusTypeAll && this.filter.bookmardIds === 'all' && this.typeTypeAll
      },
      hasCheckedStatus () {
        const { living, online, offline, noCamera, onGoingEvent } = this.filter
        const flag = living || online || offline || noCamera || onGoingEvent
        return flag
      },
      hasCheckedType () {
        const { pack, anywhere, gridPack, gridSdi, ext } = this.filter
        const flag = pack || anywhere || gridPack || gridSdi || ext
        return flag
      },
      hasCheckedBookmark () {
        const arr = this.typeData.filter(v => v.isChecked === true)
        return arr.length > 0
      }
    })
  },
  data () {
    return {
      trackAlert: false, // 轨迹弹框是否显示
      statusData: JSON.parse(JSON.stringify(MySourceStatus)),
      typeData: JSON.parse(JSON.stringify(MySourceType)),
      selectBySort: SelectBySorts,
      pageSize: 15,
      total: 0,
      freshTime: 60,
      freshData: [
        // map更新数据
        { label: 60, value: 60 },
        { label: 30, value: 30 },
        { label: 20, value: 20 },
        { label: 10, value: 10 }
      ],
      filter: {
        // 筛选过滤参数
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        pageNum: 1,
        pageSize: 15,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        onlineFirst: true,
        liveFirst: false,
        globalGrid: true,
        gridViaToken: true,
        onGoingEvent: false
      },
      selectSort: '', // 下拉排序
      defaultBookmark: {
        // 默认bookmark选中的值l
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      add: {
        // add ext
        show: false
      },
      show: false,
      lastFilterStatus: [],
      lastFilterType: [],
      showFilter: false,
      filterSection: false,
      filterSectionAddBtn: false,
      // 判断各选项是否全选状态
      statusTypeAll: true,
      typeTypeAll: true,
      hideBookmark: false
    }
  },
  created () {
    const onGoing = this.statusData.filter(v => v.value == 'onGoingEvent')
    if (!onGoing.length && this.openOnGoingEvent && this.$route.name !== 'SourceMap') {
      this.statusData.push({
        value: 'onGoingEvent',
        label: 'going',
        color: 'yello',
        isChecked: false
      })
    }
    if (this.$route.name == 'SourceMap') {
      const extIndex = this.typeData.findIndex(v => v.value == 'ext')
      if (extIndex >= 0) {
        this.typeData.splice(extIndex, 1)
      }
    }

    this.queryInfo(this.$route.name, 'init')
    Bus.$on('updateMySource', () => {
      this.queryInfo(this.$route.name, 'init')
    })
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // 搜索函数
    ...mapMutations('common', ['CHANGE_SOURCESize']),
    search (val) {
      mySourceAnalyze('mySourceSearch', this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.condition = val
      this.queryInfo('', 'filter')
    },
    // 点击定位按钮
    chooseTrack () {
      this.$refs.mapTrack.init()
    },
    openBookmarkList () {
      mySourceAnalyze('mySourceBookmarkOpen', this.$route.path) // 谷歌统计
    },
    // 谷歌统计处理
    handleAnalyze (allVal, list, lastArr, type) {
      if (allVal != '' && allVal.all === true) {
        mySourceAnalyze(`${type}AllSelected`, this.$route.path) // 谷歌统计
        this[lastArr] =
          type == 'mySourceStatus' ? ['online', 'noCamera', 'living', 'offline'] : [
            'pack',
            'anywhere',
            'gridPack',
            'gridSdi',
            'ext',
            'gridViaToken',
            'globalGrid'
          ]
        return
      }
      if (allVal != '' && allVal.all === false) {
        mySourceAnalyze(`${type}AllUnselect`, this.$route.path) // 谷歌统计
        this[lastArr] = []
        return
      }
      list.map((v, i) => {
        if (!this[lastArr].includes(v)) { mySourceAnalyze(`${type}${v}Selected`, this.$route.path) } // 谷歌统计
      })
      this[lastArr].map((v, i) => {
        if (!list.includes(v)) { mySourceAnalyze(`${type}${v}Unselect`, this.$route.path) } // 谷歌统计
      })
      this[lastArr] = list
    },
    // 筛选
    changeFilter (obj, allVal, list, type, isSelected) {
      // 谷歌统计
      if (list && type == 'status') {
        this.handleAnalyze(allVal, list, 'lastFilterStatus', 'mySourceStatus')
      }
      if (list && type == 'type') {
        this.handleAnalyze(allVal, list, 'lastFilterType', 'mySourceType')
      }
      if (allVal === 'hideBookmark') {
        this.hideBookmark = true
        return
      }
      Object.keys(obj).map(v => {
        // this.filter 过滤参数对象
        this.filter[v] = obj[v]
      })
      if (type == 'status') {
        this.statusTypeAll = allVal.all ? true : list.length === 4 && isSelected
      } else if (type === 'type') {
        this.typeTypeAll = allVal.all ? true : list.length === 5 && isSelected
      }
      this.filter.pageNum = 1
      this.queryInfo()
      // this.$route.name != 'SourceMap' && this.queryInfo() // 筛选后查询接口
    },
    // 重置各个filter选项
    resetFilter () {
      this.filter = {
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        pageNum: 1,
        pageSize: 15,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        onlineFirst: true,
        liveFirst: false,
        globalGrid: true,
        gridViaToken: true,
        onGoingEvent: false
      }
      this.lastFilterStatus = []
      this.lastFilterType = []
      this.selectSort = this.$options.filters.FORMATSORT(this.filter)
      // 用来处理筛选选中的状态
      this.statusData.map((v, k) => {
        const obj = v
        obj.isChecked = this.filter[v.value] || false
        this.$set(this.statusData, k, obj)
      })
      this.typeData.map((v, k) => {
        const obj = v
        obj.isChecked = this.filter[v.value] || false
        this.$set(this.typeData, k, obj)
      })
      this.selectSort = this.$options.filters.FORMATSORT(this.filter)
      // bookmark
      this.defaultBookmark.bookmardIds = this.filter.bookmardIds ? this.filter.bookmardIds == 'all' ? ['all'] : this.filter.bookmardIds.split(',') : []
      this.filter.unClassified && this.defaultBookmark.bookmardIds.push('unClassified')
      this.filter.token && this.defaultBookmark.bookmardIds.push('token')
      this.show = true
      this.filter.pageSize = 15
      this.statusTypeAll = true
      this.typeTypeAll = true
      this.queryInfo()
    },
    // 排序
    changeSort (val) {
      mySourceAnalyze(`mySourceSort${val}`, this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.onlineFirst = val == 'onlineFirst'
      this.filter.liveFirst = val == 'liveFirst'
      this.$route.name != 'SourceMap' && this.queryInfo()
    },
    // 分页
    handleCurrentChange (val) {
      this.filter.pageNum = val
      this.queryInfo()
    },
    handleSizeChange (val) {
      this.filter.pageSize = val
      this.filter.pageNum = 1
      this.queryInfo()
    },
    // 更改刷新时间
    changeFresh (val) {
      this.freshTime = val
      this.queryInfo(this.$route.name, 'frequency')
    },
    // 路由跳转
    jump (val, type) {
      mySourceAnalyze(`mySource${val}`, this.$route.path) // 谷歌统计
      if (val == this.$route.name) return
      const routeName = val || this.$route.name
      this.$router.push({ name: routeName })
    },
    // 地图所需数据
    emitMapData (flag) {
      const obj = {
        query: this.filter,
        freshTime: this.freshTime,
        queryFlag: flag
      }
      this.$emit('filterMap', obj)
      this.show = true
    },
    initInfo () {
      const routeName = this.$route.name

      this.axios.post('/ccp/tvucc-user/userDevice/mySourceWithInit', {
        apiType: routeName
      }).then(res => {
        if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
          this.showFilter = true
          const result = res.data.result
          const userBehaviors = result.param
          const list = result.content
          // status
          if (userBehaviors.living) this.lastFilterStatus.push('living')
          if (userBehaviors.noCamera) this.lastFilterStatus.push('noCamera')
          if (userBehaviors.offline) this.lastFilterStatus.push('offline')
          if (userBehaviors.online) this.lastFilterStatus.push('online')
          // type
          if (userBehaviors.pack) this.lastFilterType.push('pack')
          if (userBehaviors.anywhere) this.lastFilterType.push('anywhere')
          if (userBehaviors.gridPack) this.lastFilterType.push('gridPack')
          if (userBehaviors.gridSdi) this.lastFilterType.push('gridSdi')
          if (userBehaviors.ext) this.lastFilterType.push('ext')
          if (userBehaviors) {
            Object.keys(this.filter).map(v => {
              Object.keys(userBehaviors).map(K => {
                if (v == K) {
                  this.filter[v] = userBehaviors[K]
                }
              })
            })
          }
          // 用来处理筛选选中的状态
          this.statusData.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.statusData, k, obj)
          })
          this.checkStatusTypeAll(this.statusData)
          this.typeData.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.typeData, k, obj)
          })
          this.checkTypeAll(this.typeData)
          this.selectSort = this.$options.filters.FORMATSORT(this.filter)
          // bookmark
          this.defaultBookmark.bookmardIds = this.filter.bookmardIds ? this.filter.bookmardIds == 'all' ? ['all'] : this.filter.bookmardIds.split(',') : []
          this.filter.unClassified && this.defaultBookmark.bookmardIds.push('unClassified')
          this.filter.token && this.defaultBookmark.bookmardIds.push('token')
          this.show = true
          this.filter.pageSize = 15

          // 根据不同的typeApi 做不同的操作
          if (routeName == 'SourceContent' || routeName == 'SourceList') {
            this.total = list.total || 0
            this.query(list)
            this.$emit('sendMySourceSockect', list) // 监听发送websocket
            Bus.$emit('changeFilter', true)
          }
          if (routeName == 'SourceMap') {
            const flag = false
            this.emitMapData(flag)
          }
        }
      }).catch(() => {
        Bus.$emit('changeFilter', true)
      })
    },
    queryInfo (val, type) {
      // 查询数据
      const routeName = val || this.$route.name
      let result = {}
      Bus.$emit('changeFilter', false) // 监听状态
      // if (!(routeName == 'SourceMap' && type == 'filter')) {
      //   Bus.$emit('changeFilter', false) // 监听状态
      // }
      if (routeName == 'SourceContent' || routeName == 'SourceList') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const data = JSON.parse(JSON.stringify(this.filter))
          data.token = data.anywhere
          data.globalGrid = data.gridSdi || data.gridPack
          data.gridViaToken = data.gridSdi || data.gridPack
          if (routeName == 'SourceList') {
            data.battery = true
            data.bookmarks = true
          }
          this.axios.post('/ccp/tvucc-user/userDevice/pageUserSource', data, {
            contentType: 'application/json;charset=UTF-8',
            isCancelToken: true
          }).then(res => {
            if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
              result = res.data.result
              result.list.forEach(v => {
                if (v.type == 'T' && v.platform) v.version = v.releaseStr || v.version
              })
              this.total = result.total || 0
              this.query(result)
              this.$emit('sendMySourceSockect', result) // 监听发送websocket
              Bus.$emit('changeFilter', true)
            }
          }).catch(() => {})
        }
      }

      if (routeName == 'SourceMap') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const flag = type != 'frequency'
          this.emitMapData(flag)
        }
      }
    },
    addSource () {
      // 添加外部视频源
      this.add.show = true
      mySourceAnalyze('mySourceAddExtOpen', this.$route.path) // 谷歌统计
    },
    filterSectionToggle () {
      this.filterSection = !this.filterSection
    },
    handleResize () {
      if (document.body.clientWidth > 1279) {
        this.filterSection = true
        this.filterSectionAddBtn = true
      }

      if (document.body.clientWidth < 1280) {
        this.filterSection = false
        this.filterSectionAddBtn = false
        $('.bookmarkList-cascader').hide()
      }
    },
    changeSize (command) {
      this.CHANGE_SOURCESize(command)
      switch (command) {
        case 's':
          mySourceAnalyze('mySourceChangeSmall', this.$route.path)
          break
        case 'm':
          mySourceAnalyze('mySourceChangeMedium', this.$route.path)
          break
        case 'l':
          mySourceAnalyze('mySourceChangeLarge', this.$route.path)
      }
    },
    checkStatusTypeAll (val) {
      // 判断其中online，noCamera，living， offline 四个数据是否都为true，如果都为true，则all为true
      const statusData = JSON.parse(JSON.stringify(val))
      const statusArr = statusData.filter(v => v.value != 'onGoingEvent' && v.value != 'all')
      if (statusArr.every(v => v.isChecked)) {
        this.statusTypeAll = true
      } else {
        this.statusTypeAll = false
      }
    },
    checkTypeAll (val) {
      // 判断其中pack，anywhere，gridPack，gridSdi，ext 五个数据是否都为true，如果都为true，则all为true
      const typeData = JSON.parse(JSON.stringify(val))
      const typeArr = typeData.filter(v => v.value != 'all')
      if (typeArr.every(v => v.isChecked)) {
        this.typeTypeAll = true
      } else {
        this.typeTypeAll = false
      }
    }
  },
  watch: {
    $route (to, from) {
      // 切换及后退会触发路由变化
      this.queryInfo(to.name, 'frequency')
    }
  }
}
</script>
<style lang="less" scoped>
.green {
  color: #33ab4f;
}
.deviceIcon {
  width: 12px;
  margin: 0 8px 0 15px;
  font-size: 16px;
  float: left;
}
@media (min-width: 1280px) and (max-width: 1599px) {
  .left {
    .box {
      width: 134px !important;
      margin-right: 15px;
      position: relative;

      &.width200 {
        margin-right: 10px !important;
      }
    }
  }
}

@media (max-width: 917px) {
  .left {
    .box {
      margin-top: 5px;
      margin-right: 10px;

      &.width200 {
        width: 163px !important;
      }
    }
  }
}

.top {
  padding: 37px 0.5% 24px 0;

  .sourceAddIcon:before {
    content: '\e602';
    font-size: 18px;
    cursor: pointer;
  }

  .left {
    float: left;

    .input-box {
      margin-right: 15px;
    }
  }

  .right {
    float: right;

    margin-top: 2px;

    .info {
      float: left;
      // ::v-deep .paginationBox{
      //     height: 32px;
      //     line-height: 32px;
      //     .page_dropdown{
      //         display: none;
      //     }
      // }
    }

    .map {
      i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-left: 3px;
        vertical-align: middle;
        &.red {
          background: #d03f39;
        }

        &.green {
          background: #33ab4f;
        }

        &.gray {
          background: #999;
        }
      }

      .fresh {
        width: 70px;
        display: inline-block;
        margin-right: 5px;
      }

      .xy {
        margin-left: 5px;
        color: #999;
        .red {
          color: #d03f39;
        }
        .green {
          color: #33ab4f;
        }
        .gray {
          color: #999;
        }
      }
    }
    .icon {
      margin-left: 10px;
      color: #999;

      .el-dropdown-link {
        cursor: pointer;
        color: #dbdbdb;
      }

      i {
        position: relative;
        top: -3px;

        &:before {
          font-size: 28px;
        }

        &.tabActive {
          color: #dbdbdb;
        }
      }

      .el-icon-arrow-down {
        top: 6px;
        left: -5px;
      }

      .el-icon-arrow-down:before {
        font-size: 8px;
        font-weight: 900;
      }
    }
  }

  .box {
    ::v-deep .select-box {
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .sourceListIcon:before {
    content: '\E684';
    font-size: 24px;
    cursor: pointer;
  }

  .sourceInfoIcon:before {
    content: '\E701';
    font-size: 24px;
    cursor: pointer;
  }
}

.filters-toggle {
  border: 1px solid #666666 !important;
  // padding: 2px 20px;
  line-height: 32px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
  color: #999999;
  width: 100px !important;
  display: none !important;
}

.filters-section {
  .box {
    width: 160px;
    margin-right: 15px;
    position: relative;
    float: left;

    &.set {
      height: 32px;
      line-height: 32px;
    }

    &.width200 {
      margin-right: 20px;
    }

    ::v-deep .el-dropdown {
      width: 100%;
      height: 32px;
    }

    ::v-deep .el-dropdown .el-button.el-button--primary {
      padding: 9px 20px;
    }

    ::v-deep .tree-btn {
      padding: 6px 20px;
    }

    ::v-deep .tree {
      top: 36px;
    }
  }
}
.clearFilters {
  float: left;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  margin-right: 12px;
  color: #fff;
  padding: 0 8px;
  border-radius: 4px;
  background: #5cb85c;
  .iconfont {
    font-size: 20px;
  }
}
.addBtn {
  cursor: pointer;
  position: relative;
  top: 3px;
}

// @media (max-width: 1154px) {
@media (max-width: 1279px) {
  .el-select {
    width: 100% !important;
  }

  .filters-toggle {
    display: inline-block !important;
    margin-right: 20px;
  }

  .filters-section {
    display: flex;
    position: relative;
    top: 10px;
    z-index: 99;
    left: 0;
    width: 100%;
    .box {
      width: 100%;

      .select-box {
        width: 100%;
        ::v-deep .el-select {
          width: 100% !important;
        }
      }
    }
    .last-select {
      margin-right: 0px;
    }
  }

  .left {
    display: flex;
  }

  .top {
    position: relative;
    //padding: 37px 0.5% 44px 0;
    .rightFl {
      position: absolute;
      right: 0;
    }
  }

  .iconfont {
    // padding-top: 5px;
    padding-left: 2px;
  }
}

@media (max-width: 771px) {
  .top {
    .left {
      .filters-section {
        width: 100%;

        .box {
          width: 100%;

          .select-box {
            width: 100%;

            .el-select {
              width: 100%;
            }
          }
        }
      }
    }

    .filters-section {
      position: relative;
      display: block;
      left: 0;
      top: 0;
      //width: auto;
      .box {
        width: 100%;
      }

      .select-box {
        width: 100%;

        ::v-deep .el-select {
          width: 100%;
        }
      }
    }

    .rightFl {
      position: relative;
      float: left;
      margin-left: -8px;
    }

    .box.set {
      ::v-deep .el-select {
        width: 100%;
      }
    }
  }

  .el-select {
    width: 100% !important;
  }
}

::v-deep .selectSizeBox {
  top: 20px;
  left: 50px;
}
</style>
